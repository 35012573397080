.update-password .form-group {
  display: flex;
  align-items: flex-start;
}

.update-password .form-group label {
  width: 20rem;
  font-size: 1rem;
}

.update-password-btn {
  max-width: 285px;
  background-color: var(--primary-color) !important;
  border-radius: 3px;
}

.update-password-btn:hover,
.update-password-btn:active {
  background-color: var(--secondary-color) !important;
}

@media (max-width: 768px) {
  .update-password .form-group {
    display: flex;
    flex-direction: column;
  }
}
