@import "../../custom.scss";

.input {
  max-width: 20%;
}

.input-group input {
  border: 1px solid #ced4da !important;
}

.reminder-container {
  display: flex;
}

.reminders {
  flex: 4;
}
