.full_height {
  min-height: 100vh;
  height: 100%;
}

.purple_background {
  background-color: #2d054a;
}

.purple_color {
  color: #2d054a !important;
}

.card_container {
  background-color: #ece7ed;
  border-radius: 2vw;
}

.card_title {
  color: #5e216b;
  font-size: 25px;
}

.card_body {
  width: 30vw;
  min-width: 320px;
  max-width: 720px;
}

.input_label {
  font-size: 13px;
}

.signin_btn {
  display: flex;
  justify-content: center;
  background-color: var(--primary-color) !important;
  width: 9vw;
  font-size: 18px;
  min-width: 130px;
  border: none;
  border-radius: 3px;
}

.signin_btn:hover,
.signin_btn:focus,
.signin_btn:active {
  background-color: var(--secondary-color) !important;
}

.signin_spinner {
  color: #f5bef5;
}
