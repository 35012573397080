.coupon-container {
  max-width: 285px;
}

.coupon-field.form-control {
  line-height: 3;
}

.coupon-link-btn.btn {
  display: contents;
}
