.confirm-btn {
  background-color: var(--primary-color) !important;
  font-size: 18px;
  min-width: 130px;
  border: none;
  border-radius: 3px;
}

.confirm-btn:hover,
.confirm-btn:active {
  background-color: var(--secondary-color) !important;
}

.confirm-card {
  width: 30vh;
  min-width: 320px;
  max-width: 720px;
}
