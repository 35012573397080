@import "~bootstrap/scss/functions";

// change the theme
$primary: #3a003d;
$secondary: #29b473;
$pink: #fe0058;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "pink": $pink
);

// Import Bootstrap and its default variables
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/card";
