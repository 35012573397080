.full-height {
  min-height: 100vh;
  height: 100%;
}

.purple-background {
  background-color: #2d054a;
}

.purple-color {
  color: #2d054a;
}

.card-container {
  background-color: #ece7ed;
  border-radius: 2vw;
  min-width: 320px;
}

.success-card-container {
  background-color: #ece7ed;
  min-width: 320px;
  max-width: 500px;
}

.card_img {
  width: 20vw;
  min-width: 200px;
  max-width: 250px;
  height: auto;
}

.signup-card-title {
  color: #5e216b;
  font-size: 1.3rem;
}

.success-card-title {
  font-size: 1.3rem;
}

.signup-card-body {
  width: 30vw;
  min-width: 320px;
  max-width: 720px;
}

.input-label {
  font-size: 13px;
}

.privacy-notice {
  font-size: 0.8rem;
}

.signup-btn {
  display: flex;
  justify-content: center;
  background-color: var(--primary-color) !important;
  width: 9vw;
  font-size: 18px;
  min-width: 170px;
  border: none;
  border-radius: 3px;
  box-shadow: 1px 1px 6px 2px;
}

.signup-btn:hover,
.signup-btn:active {
  background-color: var(--secondary-color) !important;
}

.signup-spinner {
  color: #f5bef5;
}

.success-card-bottom {
  cursor: pointer;
}

.bg-light-green {
  background-color: #e5f4d5;
}
