.delete-btn {
  cursor: pointer;
}

.circle {
  display: inline-block;
  width: 2.5em;
  height: 2.5em;
  font-size: 1em;
  font-weight: bold;
  line-height: 2.5em;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  vertical-align: middle;
}

.group-name,
.invite-user {
  background-color: #f3f3f3;
  min-height: 1.5em;
}

.invite-user input {
  border-radius: 0;
}

.invite-user {
  justify-content: space-between;
}

.role-toggle {
  background: none !important;
  border: none !important;
  color: #444 !important;
  box-shadow: none !important;
}

.dropdown-role .active {
  border-left: 3px solid #5e216b !important;
  background: #fff;
  color: #444;
}

.dropdown-role .dropdown-item {
  border-left: 5px solid rgba(0, 0, 0, 0);
  color: #444;
}

.role-toggle:hover {
  background: none;
  border: none;
  color: #000;
}

.role-toggle.disabled {
  background: none !important;
  color: #212529 !important;
  opacity: 1;
}

.dropdown-btn.show .role-toggle {
  background: none;
  color: #444;
}

.invite-user .role-toggle {
  width: 5em;
}

.dropdown-btn.show .role-toggle:focus {
  box-shadow: none;
}

.dropdown-role .dropdown-item:hover {
  background-color: #f7f4f7;
  color: #444;
}

.switch-field {
  display: none;
}

.switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}

.switch-field label {
  background-color: #454545;
  color: #ffffff;
  font-size: 14px;
  line-height: 1;
  text-align: center;
  padding: 8px 16px;
  margin-right: -1px;
  border: 1px solid #454545;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
  transition: all 0.1s ease-in-out;
}

.switch-field label:hover {
  cursor: pointer;
}

.switch-field input:checked + label {
  background-color: #ffffff;
  color: #454545;
  box-shadow: none;
  border-radius: 5px;
}

.switch-field label:first-of-type {
  border-radius: 5px 0 0 5px;
}

.switch-field label:last-of-type {
  border-radius: 0 5px 5px 0;
}

@media only screen and (max-width: 575.98px) {
  .invite-user .invite-form {
    width: 100%;
  }

  .invite-user input {
    border-radius: 5px;
    border: 1px solid #808181;
    font-size: 0.8rem;
  }

  .switch-field {
    display: flex !important;
    overflow: hidden;
    width: 100%;
  }

  .switch-container {
    background-color: #454545;
    border-radius: 5px;
  }

  .invite-user .dropdown-btn {
    display: none;
  }

  .role-toggle {
    font-size: 0.8rem;
  }

  .dropdown-role {
    font-size: 0.8rem;
  }

  .settings-view {
    font-size: 0.8rem;
  }
  .input-group input {
    width: 70% !important;
    display: flex !important;
    margin-bottom: 10px !important;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .invite-user .invite-form {
    width: 100%;
  }

  .invite-user input {
    width: 100% !important;
  }
}

@media (min-width: 1366px) {
  .invite-user .invite-form {
    width: 30%;
  }

  .invite-user input {
    width: 100% !important;
  }
}

@media (min-width: 1200px) {
  .invite-user,
  .setting-table {
    width: 900px;
  }
}
