@import "../../custom.scss";

.payment-container {
  display: flex;
}

.payment {
  flex: 4;
}

.empty_div {
  margin-bottom: 0.75rem;
  padding: 1rem 1.25rem;
  border: 1px solid transparent;
}

.payment .alert {
  margin-bottom: 0.25rem !important;
  padding: 0.5rem 1.25rem !important;
}
