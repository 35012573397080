.site-wrapper {
  width: 100%;
  margin: 0;
  padding: 0;
  min-height: 70vh;
}

.site-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  background-color: #fafafa;
}

.isCurrent {
  font-weight: bold;
  background-color: #522683;
}

.isCurrent a {
  color: white;
}

.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.align-items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.sidebar {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  border-right: 1px solid #eeeeee;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  width: 200px;
  padding: 0;
  top: 0;
  bottom: auto;
}

.sidebar .menu .menu-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: .8em 1em;
  font-size: 1rem;
  font-weight: 600;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.sidebar .menu .menu-item:hover, .sidebar .menu .menu-item.active {
  background-color: #554a88;
  color: white;
}

.menu-dropdown {
  display: none;
  position: sticky;
  padding: 0;
  top: 0;
  bottom: auto;
}

.menu-dropdown .dropdown-toggle {
  background-color: var(--primary-color) !important;
}

.sidebar ul {
  list-style: none;
}

@media screen and (max-width: 768px) {
  .menu-scroll {
    display: none;
  }
  .sidebar {
    max-width: 55px;
  }
  .menu-dropdown {
    display: block;
  }
}

.main-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 0;
}

.menu-wrapper {
  position: relative;
  z-index: 1;
}
