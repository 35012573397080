.add_more_container:hover,
.add_more_container:focus {
  text-decoration: none;
}

.add_more {
  color: #5e216b;
}

.copy {
  color: rgba(0, 0, 0, 0.6);
  border: none;
  background: transparent;
}

.copy:hover,
.copy:focus {
  color: #000;
}

.copy_key {
  border: 1px solid #6c757d !important;
  color: rgb(88, 81, 81) !important;
  width: 34px !important;
  height: 32px !important;
  background: transparent !important;
  padding: 2px 6px !important;
  border-radius: 0.25rem !important;
}

.copy_key:hover,
.copy_key:focus {
  border: 1px solid #6c757d !important;
  background: none !important;
  color: #000 !important;
  padding: 2px 6px !important;
  outline: none !important;
  box-shadow: none !important;
}

.copy_key:active {
  transform: scale(0.98);
}

.key-row {
  width: 50%;
}

.index-details .breadcrumb {
  background-color: white;
  padding-left: 0;
}

.index-details .card-header {
  background-color: white;
}

.index-details .header-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.index-details .card-header .btn {
  background-color: white !important;
  color: #212529;
}
.index-details .card-header .btn-primary {
  background-color: #5e216b !important;
  color: white;
}

.index-details .card-subtitle {
  font-weight: 600;
}

.index-details .card-footer {
  background-color: white;
}

.index-details .key-header {
  display: flex;
  align-items: center;
}

.index-details .input-group {
  max-width: 300px;
}

.index-details .version.input-group {
  max-width: 150px;
}

.index-details .version .input-group-prepend span {
  background: var(--primary-color) !important;
  color: white;
}

.index-details .delete-key {
  background-color: white;
  border: none;
  color: #007bff;
}

.index-details .delete-key:hover {
  background-color: white;
  text-decoration: underline;
  border: none;
  color: #007bff;
}

.index-details thead tr th {
  border-top: none;
}

.index-details .breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: ">";
}

.index-details .data_preview .row {
  opacity: 1 !important;
  color: #fff;
  border-radius: 4px 4px 0 0;
  width: 100%;
  height: 38px;
  align-items: center;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.index-details .order {
  width: 2.15rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px 0 0 0;
}

.index-details .index {
  background-color: #5ca471;
}

.index,
.ignored,
.metadata {
  cursor: pointer;
}

.index-details .index .order {
  background-color: #509169;
}

.index-details .metadata {
  background: #2974b5;
}

.index-details .metadata .order {
  background: #25669c;
}

.index-details .ignored {
  background: #ebedee;
  color: #212529 !important;
}

.index-details .ignored .order {
  background-color: #c3c7ca;
}

.index-details .title {
  position: relative;
  flex: 1 1 auto;
}

.tooltip-inner {
  max-width: 300px;
  text-align: left;
}

.data_preview svg {
  cursor: pointer;
}

.data_preview .table th {
  width: 2rem;
  background: #ebedee;
}

.data_preview .table {
  border: 1px solid #ced4da;
}

.index-details .data_role {
  min-width: 150px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.header-main a {
  text-decoration: none;
  color: #495057;
}

.confirm_delete .delete_btn {
  background: var(--primary-color) !important;
}

.confirm_delete .delete_btn:focus {
  background: var(--secondary-color) !important;
}

.blur-bg {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}

.ml-50 {
  margin-left: 50%;
}

.joyride-search-button,
.joyride-search-button:hover,
.joyride-search-button:focus,
.joyride-search-button:active {
  background-color: #eeeeee;
  color: black !important;
  border-color: #eeeeee;
  text-decoration: none;
}

@media only screen and (max-width: 767px) {
  .ml-50 {
    margin-left: 0px;
    margin-bottom: 10px;
  }
}

.input-group-addon {
  border: none !important;
  border-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  height: calc(1.5em + 0.75rem + 2px);
}
.input-group input {
  border: none !important;
}

.api-specification-button {
  width: 10.5rem;
}
.full-documentation-button {
  width: 10.5rem;
}

.input-group-addon span {
  border: none !important;
}

@media (max-width: 576px) {
  .api-specification-button {
    margin-bottom: 10px;
  }
  .full-documentation-button {
    margin-bottom: 10px;
  }
}
