.dataset-table {
  box-shadow: 0px 0px 0px 0.5px #d5d5d5, 0px 0px 5px 1px #d5d5d5;
  border-radius: 0.5rem;
  max-width: 98%;
}

.dataset-table th {
  border: none;
}

.dataset-table th:first-of-type {
  border-top-left-radius: 0.5rem;
}

.dataset-table th:last-of-type {
  border-top-right-radius: 0.5rem;
}

.dataset-table tr:last-of-type td:first-of-type {
  border-bottom-left-radius: 0.5rem;
}

.dataset-table tr:last-of-type td:last-of-type {
  border-bottom-right-radius: 0.5rem;
}

.dataset-table thead {
  color: #4c4c4c;
  background-color: #f2f2f2;
  border-radius: 0.5rem;
}

.dataset-table .dropdown .dropdown-menu {
  transform: translate3d(-70px, 10px, 0px) !important;
  min-width: 6rem;
}

.dataset-table .dropdown .dropdown-menu .dropdown-item {
  width: 100%;
}

.upload-file {
  width: 50%;
  border: 1px solid #817e7e;
  border-radius: 5px;
  cursor: pointer;
}

.nav-link {
  cursor: pointer;
}

.dataset-btn {
  background-color: var(--primary-color) !important;
  border-radius: 3px !important;
}

.dataset-btn:disabled {
  background-color: var(--primary-color);
  opacity: 0.8;
}

.dataset-btn:hover,
.dataset-btn:active:hover,
.dataset-btn:focus {
  background-color: var(--secondary-color) !important;
}

.dataset-btn label {
  cursor: pointer;
  padding: 0.5rem !important;
}

.dataset > .table-responsive {
  overflow-x: initial;
}

.dataset-table .dataset_name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 15rem;
}

@media only screen and (max-width: 768px) {
  .dataset > .table-responsive {
    overflow-x: auto;
  }
}

.preview {
  overflow-x: scroll;
}

@media only screen and (max-width: 375px) {
  .dataset .nav-link {
    padding: 0.5rem 0.5rem !important;
    font-size: 0.9rem;
  }

  .dataset p {
    font-size: 0.9rem;
  }

  .upload-file {
    width: 100%;
  }
}

.download-btn {
  position: absolute;
  top: 2%;
  right: 1%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 0.15rem;
  border-radius: 0.25rem;
  max-height: 3rem;
  max-width: 3rem;
  padding: 1%;
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}

.json-res {
  padding: 5px;
}

.finish-btn {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  font-family: inherit;
  overflow: visible;
  background-color: transparent;
  color: #5e216b;
  border: 2px solid #5e216b;
  padding: 0.6rem 25px;
  font-size: 0.75rem;
  letter-spacing: 0.075rem;
  line-height: 1.5;
  transition: 0.3s ease;
  border-radius: 0.25rem !important;
  text-transform: uppercase !important;
  cursor: pointer;
}

.finish-btn:hover {
  cursor: pointer;
  background-color: #5e216b;
  color: #fff;
  outline: none;
}

#file-size {
  color: #5e216b;
}
code {
  padding: 0.2em 0.4em;
  margin: 0;
  font-size: 85%;
  background-color: rgba(127, 127, 127, 0.3);
  border-radius: 6px;
}
