.preview-modal {
  max-width: 100%;
}

.preview-modal .modal-content {
  width: 90vw;
  margin: auto;
}

.preview-modal button {
  color: white;
  font-weight: normal;
  opacity: 0.6;
}

.preview-modal button:hover {
  color: white;
  opacity: 1;
  font-weight: 600;
}

@media only screen and (max-width: 1200px) {
  .preview {
    overflow-x: auto;
  }
}

@media only screen and (max-width: 535px) {
  .preview-modal .modal-content {
    width: 95%;
  }
}

.blur-bg {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}

.modal-title {
  width: 100%;
  text-align: center;
  font-weight: bold;
}
