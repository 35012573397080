@import "../../custom.scss";

.login-btn.btn {
  border-width: 2px;
  width: 82px;
}

.patent-prior-art-report-icon {
  width: 60%;
}

.invalidation-report-icon {
  width: 40%;
}

.green-check-text {
  font-size: 1.3rem;
}

p.p-border-left {
  border-left: 0.15rem solid #999;
}

.carousel-indicators li {
  width: 12px;
  height: 12px;
  border: none;
  border-radius: 50%;
  margin-left: 1px;
  background-color: $gray-600;
}

.carousel-indicators li.active {
  width: 30px;
  height: 12px;
  border: none;
  border-radius: 1rem;
  background-color: $secondary;
}

.fade-enter {
  opacity: 0;
}

.fade-exit {
  opacity: 1;
}

.fade-enter-active {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
}

.fade-enter-active,
.fade-exit-active {
  transition: opacity 200ms;
}

#status-tooltip-score {
  cursor: pointer;
  background: none;
}

.patent_info {
  box-shadow: 5px 5px 6px rgb(228, 223, 223);
}

.invalidation-progress-bar {
  height: 2.5rem;
}

@media (min-width: 720px) {
  .skip-the-product-banner {
    clip-path: polygon(100% 0, 100% 62%, 0 100%, 0 100%, 0 0);
  }

  .skip-the-product-banner h2 {
    width: 75%;
  }

  img.done-for-you-service-img,
  img.data-catalogs-img {
    max-width: 75%;
  }

  img.slider-img {
    max-width: 85%;
  }
}
