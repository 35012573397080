.typing-icon-container {
  display: flex;
  align-items: center;
}

.typing-dot {
  width: 10px;
  height: 10px;
  background-color: #333;
  border-radius: 50%;
  margin-right: 5px;
  animation: typing 1s infinite alternate;
}
table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 20px;
}

th,
td {
  padding: 8px;
  border: 1px solid black;
  text-align: left;
}

th {
  background-color: #3a003d85; /* Optional: Adds a background color to header cells */
}

@keyframes typing {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

.typing-dot:nth-child(2) {
  animation-delay: 0.2s;
}

.typing-dot:nth-child(3) {
  animation-delay: 0.4s;
}

.symbol-icon {
  display: inline-flex; /* Use flexbox for alignment */
  justify-content: center; /* Align text horizontally */
  align-items: center; /* Align text vertically */
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: #ccc; /* Default background color */
  text-align: center; /* Align text horizontally */
  line-height: 1; /* Reset line height */
  margin-bottom: 5px;
}
.symbols {
  width: 80%;
  margin: auto;
}
.message-text {
  width: 80%;
  margin: auto;
  font-size: 15px;
}
.symbol-text {
  margin-left: 10px;
  font-weight: bold;
}
.chatbot-messages::-webkit-scrollbar {
  width: 10px;
}

.chatbot-messages::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

.chatbot-messages::-webkit-scrollbar-thumb {
  visibility: visible;
}

.chatbot-messages:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}

.chatbot-container {
  width: 100%;

  overflow: hidden;
}

.chatbot-messages {
  width: 70%;
  margin-left: auto;
  height: 600px;
  overflow-y: scroll;
  /* border: none; */
}

.message {
  padding: 20px;
  border-radius: 5px;
  margin: 10px;
}

.user {
  margin: 0;
  margin-left: 10px;
  padding-bottom: 10px;
}

.bot {
  margin-bottom: 0;
  padding-bottom: 10px;
}

.chatbot-input {
  resize: none;
  height: fit-content;
  border: none !important;
  background-color: var(--grey-100) !important;
}
.chatbot-input:focus {
  outline: none !important;
  box-shadow: none !important;
}

.input-field {
  flex: 1;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.thumb {
  background-color: transparent;
  border: none !important;
  transition: transform 0.2s, font-size 0.2s; /* Add transition for smooth effect */
  font-size: 24px;
}

.thumb:hover {
  cursor: pointer;
  transform: scale(1.1); /* Enlarge the button slightly on hover */
}
.thumb-active {
  transform: scale(1.1);
}

.custom-radio {
  width: 30px; /* Adjust width as needed */
  height: 30px; /* Adjust height as needed */
  border: 2px solid #000; /* Border color */
  border-radius: 50%; /* Make it round */
  margin-right: 10px; /* Adjust margin as needed */
  margin-left: 20px;
}
.purple_btn {
  background-color: var(--primary-color) !important;
  box-shadow: none !important;
}

.purple_btn:hover,
.purple_btn:active {
  background-color: var(--secondary-color) !important;
}
.search-dropdown-toggle {
  color: #000 !important;
  width: 150px;
}
.search-dropdown-toggle:hover {
  color: #000;
}
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: #000;
}

.info_box {
  background-color: #f5f5f5;
}

.cyan_btn {
  color: rgba(0, 0, 0, 0.849) !important;
}

.cyan_btn:hover {
  background-color: rgb(27, 231, 187) !important;
  border-color: rgb(27, 231, 187) !important;
}

.magenta_btn:hover {
  background-color: rgb(199, 5, 238) !important;
  border-color: rgb(199, 5, 238) !important;
}

.white_btn {
  color: rgb(72, 20, 83);
  background-color: white;
  width: 30px;
  height: 30px;
  border-radius: 15px;
}

.wiki-bg {
  background: #eef1f1;
}

.score {
  background: #91a6ac;
  width: 5rem;
}

.wiki-result {
  resize: none;
}

#api {
  resize: none;
}

.demo-body {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.demo-content {
  flex: 1 0 auto;
}

.demo-footer {
  flex-shrink: 0;
}

.demo-tab {
  text-decoration: none;
  color: #000;
}

.demo-tab:hover {
  text-decoration: none;
}

.demo-tab.active {
  color: #007bff;
}

.demo-input:focus {
  outline: none !important;
  border: 1px solid #dfe1e5;
  box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
}

.search_btn {
  margin-left: auto;
  width: 100%;
  min-width: 80px;
  max-width: 134px;
}

.demo_table th,
.demo_table td {
  border: none;
}

.demo_table {
  border: 2px solid #f2f2f2;
}

.blur-bg {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}

.demo-select {
  width: 210px;
}

#search-text-example {
  text-align: left;
  color: #fe0058;
  background-color: rgba(127, 127, 127, 0.3);
  border-radius: 6px;
  margin: 10px 0 0 0;
  padding: 0.2em 0.4em;
}

.rc {
  border-radius: 10px;
}
.rc-100 {
  height: auto !important;
  border-radius: 30px;
}
.rc-top {
  border-radius: 10px 10px 0 0;
}
.rc-bottom {
  border-radius: 0 0 10px 10px;
}
.rc-bottom-right {
  border-radius: 0 10px 10px 10px;
}
.rc-bottom-left {
  border-radius: 10px 0 10px 10px;
}

/* https://www.tailwindshades.com/#color=283%2C58%2C39.411764705882355&step-up=12&step-down=7&hue-shift=49&name=seance&base-stop=5&v=1&overrides=e30%3D */
.bg-purple-100 {
  background-color: var(--purple-100);
}
.bg-purple-200 {
  background-color: var(--purple-200);
}
.bg-grey-100 {
  background-color: var(--grey-100);
}
.bg-grey-200 {
  background-color: var(--grey-200);
}

.mx-90 {
  max-width: 90%;
}
.minw-10 {
  min-width: 5%;
}

/* remove margin that ReactMarkdown adds to p element.  */
.mx-90 > p {
  margin-bottom: 0;
}

textarea {
  max-height: 100px;
  overflow-y: auto;
  resize: none; /* Disable manual resizing */
}

/* Scrollbar width */
textarea::-webkit-scrollbar {
  width: 10px;
}

/* Scrollbar thumb (the draggable part) */
textarea::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Show scrollbar thumb on hover */
textarea:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}

.switch {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider:before {
  transform: translateX(46px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* New styles for the text */
.slider .on,
.slider .off {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
}

.slider .on {
  left: 8px;
  display: none;
}

.slider .off {
  right: 8px;
}

input:checked + .slider .on {
  display: block;
}

input:checked + .slider .off {
  display: none;
}

.pbox {
  border: 1px solid var(--primary-color);
  border-radius: 5px;
}

.dropdown-menu {
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15), 2px 2px 3px rgba(0, 0, 0, 0.15), 3px 3px 6px rgba(0, 0, 0, 0.15);
}

.w-icon-25 {
  width: 80%;
}
@media (min-width: 768px) {
  .w-icon-25 {
    width: 30%;
  }
}
