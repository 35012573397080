:root {
  --primary-color: #4a1074;
  --secondary-color: #380b55;
  --dark-purple: #432866;
  --purple-100: #f2ccf0;
  --purple-200: #f2ccf0;
  --grey-100: #e9eaec;
  --grey-200: #d3d5da;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: Arial;
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: bold !important;
}

.MuiTablePagination-root p {
  margin: inherit;
}

.MuiCircularProgress-circle {
  color: #2d054a;
}

@font-face {
  font-family: "Avenir";
  src: url("./assets/fonts/AvenirLTStd-Book.eot");
  src: url("./assets/fonts/AvenirLTStd-Book.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/AvenirLTStd-Book.svg#AvenirLTStd-Book") format("svg"),
    url("./assets/fonts/AvenirLTStd-Book.ttf") format("truetype"),
    url("./assets/fonts/AvenirLTStd-Book.woff") format("woff"),
    url("./assets/fonts/AvenirLTStd-Book.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Avenir-Bold";
  src: url("./assets/fonts/AvenirLTStd-Black.eot");
  src: url("./assets/fonts/AvenirLTStd-Black.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/AvenirLTStd-Black.svg#AvenirLTStd-Black") format("svg"),
    url("./assets/fonts/AvenirLTStd-Black.ttf") format("truetype"),
    url("./assets/fonts/AvenirLTStd-Black.woff") format("woff"),
    url("./assets/fonts/AvenirLTStd-Black.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}

.font-avenir {
  font-family: "Avenir", Arial, Helvetica, sans-serif;
}

.font-avenir-bold {
  font-family: "Avenir-Bold", Arial, Helvetica, sans-serif;
}

#root {
  padding: 0 !important;
  margin: 0 !important;
}

.demo-btn {
  width: 200px !important;
  background: #15c39a !important;
  border-radius: 20px !important;
}

.demo-btn:hover {
  background-color: #15c39a !important;
}

.learn-more-btn {
  border-radius: 20px !important;
  width: 140px !important;
  font-size: 14px !important;
  line-height: 25px !important;
  letter-spacing: -0.5444444px !important;
  background-color: #d407ff !important;
  box-shadow: 4px 4px 8px rgba(224, 134, 255, 0.5) !important;
}

.service,
.terms {
  font-size: 14px;
}
.traindex-logo {
  width: 206px;
  height: 44px;
}

.terms-of-use-text,
.privacy-rules {
  font-size: 18px;
  line-height: 21px;
}
.restrictions-list {
  list-style-type: square;
}

.topline {
  font-size: 2.4rem;
}

.trial-alert {
  color: #856404;
  font-weight: bold;
  text-decoration: underline;
}

/* // Extra large devices (large desktops, 1024px and up) */
@media (min-width: 1400px) {
  .container {
    max-width: 1320px !important;
  }
}

@media (min-width: 1200px) {
  .body-container {
    max-width: 70%;
  }
}

/* // Large devices (desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  .body-container {
    max-width: 80%;
  }
}

@media only screen and (min-width: 375px) and (max-width: 575px) {
  .demo-btn {
    width: 120px !important;
  }
}

@media only screen and (max-width: 375px) {
  .demo-btn {
    width: 200px !important;
  }
}

@media (min-width: 720px) {
  .topline {
    font-size: 3rem;
  }
}
