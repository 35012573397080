.dashboard_layout_container {
  min-height: 100vh;
}

.layout_row {
  margin: 0;
}

.sidebar_column {
  padding: 0;
  min-width: 110px;
  max-width: 200px;
}

.content_column {
  background-color: #f2f3f3;
}

.content_column_body {
  background-color: #f2f3f3;
}

.nav-item > .active {
  background: #f7f7f7;
}

@media (min-width: 576px) and (max-width: 768px) {
  .body-container {
    width: 90%;
  }
}
