@import "../../custom.scss";

.header {
  background-color: $primary;
}

.landing-page-navbar-link.nav-link {
  color: white;
  &:hover {
    color: $secondary;
  }
}

.landing-page-navbar-link-light.nav-link {
  color: $primary;
  &:hover {
    color: $secondary;
  }
}
