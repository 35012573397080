.index-page .btn {
  background-color: var(--primary-color);
  outline: none;
}

.index-page .btn:hover,
.index-page .btn:active:hover {
  background-color: var(--secondary-color);
}

.create-index {
  background-color: var(--primary-color) !important;
}

.create-index:hover {
  background-color: var(--secondary-color) !important;
}

.back-btn {
  background: none !important;
  border: none !important;
  color: #0000ee !important;
}

.back-btn:hover {
  background: none !important;
  border: none !important;
  color: #0000ee !important;
}

.back-btn:active,
.back-btn:active:focus,
.back-btn:focus {
  background: none !important;
  border: none !important;
  outline: none !important;
  color: #551a8b !important;
  box-shadow: none !important;
}

.default-select {
  outline: none;
  width: 280px;
}

.default-select .css-1pahdxg-control {
  box-shadow: var(--primary-color) !important;
  border-color: hsl(0, 0%, 80%);
}

.default-select .css-1pahdxg-control:hover {
  border-color: hsl(0, 0%, 80%);
}

.default-select .css-9gakcf-option {
  background-color: var(--secondary-color);
}

.step-btn {
  background-color: var(--primary-color) !important;
  min-width: 75px !important;
}

.step-btn:hover,
.step-btn:active,
.step-btn:active:focus,
.step-btn:focus {
  background-color: var(--secondary-color) !important;
  color: white !important;
  outline: none !important;
  box-shadow: none !important;
}

.react-joyride__overlay {
  height: 100% !important;
}

@media (max-width: 576px) {
  .index-page .table {
    font-size: 0.8em;
  }
}

.index-table {
  table-layout: fixed;
  word-wrap: break-word;
}
