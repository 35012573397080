.navbar_container {
  background-color: #fff;
}

.full-width {
  width: 100vw;
}

.navbar-toggler {
  background-color: #fff;
  color: #5e216b;
}

.navbar-toggler:focus {
  outline: none;
}

.nav-link {
  color: black;
}

.nav > div > .active {
  color: var(--primary-color);
  font-weight: 600;
}

.nav-link:hover {
  color: var(--primary-color);
}

.dropdown-item.active {
  background-color: var(--primary-color);
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #fff;
  background-color: var(--secondary-color);
}

.navbar-underline {
  border-top: 4px solid var(--primary-color);
  background-color: var(--primary-color);
  border-bottom: 4px solid var(--primary-color);
  width: 100vw;
}
