.subscribe-btn {
  max-width: 285px;
  background-color: var(--primary-color) !important;
  border: none;
}

.subscribe-btn:hover,
.subscribe-btn:active,
.subscribe-btn:focus {
  color: white !important;
  box-shadow: none !important;
  background-color: var(--secondary-color) !important;
}

.verify-btn {
  max-width: 160px;
}

.text-orange {
  color: orange;
}

.modal-header {
  background-color: #48217a;
  color: white;
}

.subscription-container.container {
  max-width: 720px;
}

.subscription-select {
  max-width: 285px;
}

tr td .button {
  white-space: nowrap;
}

.verify-amount span {
  padding-right: 0;
}
