.join-input {
  list-style: none;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.landing-page-send-button {
  background-color: #fe0058 !important;
  font-size: 16px;
  height: 40px;
}

.social-media-icons {
  width: 1.4rem;
}

.description {
  color: #b0b0b0 !important;
}

.footer-container {
  background-color: #3a003d;
  color: white;
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin: 0 !important;
  display: grid;
  grid-template-columns: 30% 20% 15% 30%;
}

.footer-bottom {
  background-color: black;
  color: white;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  text-align: center;
  font-size: 14px;
}

.footer-container h4 {
  color: #40ae70;
  font-size: 1.8rem;
}

.footer-container a,
.footer-container a:visited {
  color: white;
  text-decoration: none;
  font-weight: 300 !important;
}

.footer-container a:hover {
  color: #c0c0c0;
  text-decoration: none;
  font-weight: 300 !important;
}

.footer-container li {
  margin-left: 12px;
}

.first-coloumn {
  padding-left: 5rem;
  padding-right: 2rem;
}

.input-group > .landing-page-form-input {
  min-width: 16rem;
  border-radius: 5px !important;
  height: 40px;
  flex: unset !important;
}

@media only screen and (max-width: 1200px) {
  .footer-container {
    grid-template-columns: 50% 20% 20%;
  }
  .first-coloumn {
    padding-left: 2rem !important;
  }
}

@media only screen and (max-width: 767px) {
  .footer-container {
    font-size: 16px;
    grid-template-columns: 50% 40%;
  }
  .first-coloumn {
    padding-left: 2rem !important;
  }
  .footer-bottom {
    font-size: 12px;
  }
  .input-group > .landing-page-form-input {
    min-width: 15rem;
  }
}

@media only screen and (max-width: 575px) {
  .footer-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .first-coloumn {
    padding-left: 2rem !important;
  }
}

.input-group > .landing-page-form-input::placeholder {
  color: gray;
}

.landing-page-send-svg {
  z-index: 9999;
  cursor: pointer;
  border: 1px solid #ced4da;
}

.errorMessage,
.successMessage {
  margin: 10px 0;
  padding: 10px 10px 10px 0;
  border-radius: 3px 3px 3px 3px;
}

.errorMessage {
  color: #d8000c;
}
.successMessage {
  color: #40ae70;
}
