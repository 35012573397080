#status-tooltip-score {
  cursor: pointer;
  background: none;
}

.patent_info {
  box-shadow: 5px 5px 6px rgb(228, 223, 223);
}

.prior-art-progress-bar {
  height: 2.5rem;
}
