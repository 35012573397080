.profile-container {
  max-width: 720px !important;
}

.profile-btn {
  min-width: 150px;
  max-width: 285px;
  background-color: var(--primary-color) !important;
  border-radius: 3px !important;
}

.profile-btn:hover,
.profile-btn:active,
.profile-btn:focus {
  background-color: var(--secondary-color) !important;
}
